<template>
  <vue-flatpickr-component
    class="vuestic-date-picker"
    :value="pickerValue"
    :config="pickerConfig"
    @input="onValueChange"
  />
</template>

<script>
import Vue from 'vue'
import VueFlatpickrComponent from 'vue-flatpickr-component'
import moment from 'moment'
import flatpickr from 'flatpickr'
const Arabic = require('flatpickr/dist/l10n/ar.js').default.ar
const English = require('flatpickr/dist/l10n/default.js').default

export default {
  components: { VueFlatpickrComponent },
  props: {
    value: {},
    config: {
      type: Object
    }
  },
  data() {
    return {
      minDate: '',
      maxDate: ''
    }
  },
  computed: {
    pickerConfig() {
      return {
        dateFormat: 'Y-m-d',
        ...this.config,
        minDate: this.minDate,
        maxDate: this.maxDate,
        onOpen: [this.onPickerOpen],
        locale: Vue.i18n.locale() === 'ar' ? Arabic : English
      }
    },
    locale() {
      return Vue.i18n.locale()
    },
    pickerLocale() {
      return Vue.i18n.locale() === 'ar' ? Arabic : English
    },
    pickerValue() {
      if (this.value) {
        if (this.config.mode === 'range') {
          return [this.value.from, this.value.to].join(
            this.pickerLocale.rangeSeparator
          )
        }
        return this.value
      }
      return ''
    }
  },
  mounted() {
    // initialize dates
    this.minDate = this.config.minDate
    this.maxDate = this.config.maxDate

    flatpickr.localize(this.pickerLocale)
  },
  methods: {
    onValueChange(value) {
      if (value) {
        if (this.pickerConfig.mode === 'range') {
          const [from, to] = value.split(this.pickerLocale.rangeSeparator)

          console.log('from', from)
          console.log('to', to)

          if (!!this.config.maxRange) {
            if (!!from) {
              this.minDate = moment(from)
                .subtract(this.config.maxRange, 'days')
                .toDate()

              this.maxDate = moment
                .min([
                  moment(
                    this.config.maxDate === 'today'
                      ? undefined
                      : this.config.maxDate
                  ),
                  moment(from).add(this.config.maxRange, 'days')
                ])
                .toDate()
            }
          }

          this.$emit('rangeChange', { from, to })
        }

        this.$emit('input', value)
      }
    },
    onPickerOpen(selectedDates, dateStr, instance) {
      this.minDate = this.config.minDate
      this.maxDate = this.config.maxDate
    }
  },
  watch: {
    locale() {
      flatpickr.localize(this.pickerLocale)
    }
  }
}
</script>

<style lang="scss" scoped>
.vuestic-date-picker {
  border: 1px solid #ccc;
  border-radius: 0.3rem;
  margin: 2px 0;
  padding: 5px;
  min-width: 12rem;
  &:focus {
    outline: none;
  }
}
</style>
