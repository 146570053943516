<template>
  <div>
    <div class="va-row">
      <div class="flex md12 xs12">
        <vuestic-data-table
          class="text-center"
          :apiMode="true"
          :apiUrl="apiUrl"
          :httpOptions="httpOptions"
          :defaultPerPage="defaultTablePerPage"
          :tableFields="translatedTableFields"
          :perPageSelectorShown="false"
          :actions="true"
          :additionalActions="true"
          :filterInputShown="false"
        >
          <template slot="additionalTableControl">
            <div class="filter-controls">
              <div class="d-flex flex-column align-items-start date-range">
                <label class="m-0">{{
                  $t('billing.payments-history.filters.date-range')
                }}</label>
                <date-picker
                  class="vuestic-date-picker"
                  :value="dateRange"
                  @rangeChange="dateRange = $event"
                  :config="{
                    maxDate: today.toDate(),
                    maxRange: 90,
                    mode: 'range'
                  }"
                />
              </div>
            </div>
          </template>
        </vuestic-data-table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DatePicker from '../../elements/DatePicker.vue'
import moment from 'moment'
import momentTimezone from 'moment-timezone'

export default {
  components: {
    DatePicker
  },
  data() {
    return {
      dateRange: {
        from: moment()
          .subtract('30', 'days')
          .format('YYYY-MM-DD'),
        to: moment().format('YYYY-MM-DD')
      },
      today: moment(),
      httpOptions: {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
          'ngrok-skip-browser-warning': true,
          organization: localStorage.getItem('organizationId'),
          'Accept-Language': localStorage.getItem('locale'),
          timezone: momentTimezone.tz.guess()
        }
      },
      tableFields: [
        {
          name: '__sequence',
          title: '#',
          dataClass: 'text-light'
        },
        {
          name: 'date',
          title: 'billing.payments-history.table.date'
        },
        {
          name: 'description',
          title: 'billing.payments-history.table.description'
        },
        {
          name: 'amount',
          title: 'billing.payments-history.table.amount'
        },
        {
          name: 'currency',
          title: 'billing.payments-history.table.currency'
        }
      ],
      defaultTablePerPage: 10
    }
  },
  watch: {
    currentTenant() {
      const organizationId = localStorage.getItem('organizationId')
      this.httpOptions.headers.organization = organizationId
    }
  },
  methods: {},
  computed: {
    ...mapGetters(['tenantID', 'currentJera']),
    apiUrl() {
      return `${process.env.VUE_APP_NEW_API}jera/payment-list/?fromDate=${this
        .dateRange.from || this.dateRange.to}&toDate=${this.dateRange.to ||
        this.dateRange.from}&itemsPerPage=${this.defaultTablePerPage}`
    },
    translatedTableFields() {
      return this.tableFields.map(field => {
        return {
          ...field,
          title: this.$t(field.title)
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.form-group {
  min-width: 18rem;
}
</style>
